import { Plugins } from "@capacitor/core";
import axios from "axios";

import { jwtDecrypt } from "../../common/helper.auth";
import { API_MOCK_ENABLE, API_MOCK_PATH, API_URL } from "../../common/config";

const { Storage } = Plugins;

const state = () => ({
  authData: {
    token: "",
    refreshToken: "",
    tokenExp: "",
    userId: "",
    userName: "",
  },
  loginStatus: "success",
});

const getters = {
  getLoginStatus(state) {
    return state.loginStatus;
  },
  getAuthData(state) {
    return state.authData;
  },
};

const actions = {
  async logoutUser({ commit }) {
    await Storage.remove({
      key: "access_token",
    });
    commit("saveAuthToken", null);
    commit("saveLoginStatus", "failed");
  },
  async loginUser({ commit }, payload) {
    // console.log(payload);
    const response = await (API_MOCK_ENABLE
      ? axios.get(API_MOCK_PATH + "login.json")
      : axios.get(API_URL + "Auth", { params: payload }));
    if (response.status == 200 || response.status == 201) {
      if (response.data.access_token) {
        try {
          await Storage.set({
            key: "access_token",
            value: response.data.access_token,
          });
        } catch (err) {
          console.log("ERROR: SET ACCESS TOKEN", err);
        }
      }
      if (response.data.refresh_token) {
        await Storage.set({
          key: "refresh_token",
          value: response.data.refresh_token,
        });
      }

      commit("saveAuthToken", response.data);
      commit("saveLoginStatus", "success");
    } else {
      commit("saveLoginStatus", "failed");
    }
  },
  async loadStorageTokens({ commit }) {
    const access_token = await Storage.get({ key: "access_token" });
    const refresh_token = await Storage.get({ key: "refresh_token" });
    if (access_token) {
      if (access_token && refresh_token) {
        const tokenData = {
          access_token: access_token.value,
          refresh_token:
            refresh_token.value == "undefined"
              ? ""
              : refresh_token.value
              ? refresh_token.value
              : "",
        };
        commit("saveAuthToken", tokenData);
      }
    }
  },

  async saveTokensToStorage({ commit }, payload) {
    await Storage.set({
      key: "access_token",
      value: payload.access_token,
    });
    await Storage.set({
      key: "refresh_token",
      value: payload.refresh_token,
    });
    commit("saveTokenData", payload);
  },
};

const mutations = {
  saveAuthToken(state, payload) {
    let newAuthData = {};
    if (!payload) {
      state.authData = {};
    } else {
      if (payload.access_token == "OK") {
        newAuthData = {
          token: payload.access_token || "",
          userId: "",
          userName: "",
          refreshToken: "",
          tokenExp: "",
        };
      } else {
        const jwtDecodeUserInfo = jwtDecrypt(payload.access_token);

        newAuthData = {
          token: payload.access_token ? payload.access_token : "",
          userId: payload.user_id ? payload.user_id : "",
          userName: payload.user_name ? payload.user_name : "",
          refreshToken: payload.refresh_token ? payload.refresh_token : "",
          tokenExp: jwtDecodeUserInfo.exp ? jwtDecodeUserInfo.exp : "",
          // userId: jwtDecodeUserInfo.sub,
          // userName: jwtDecodeUserInfo.username,
        };
      }

      state.authData = newAuthData;
    }
  },
  saveLoginStatus(state, status) {
    state.loginStatus = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
