<script>
// import { onBackgroundMessage } from "firebase/messaging/sw"
import { onMessage } from "firebase/messaging";
import { getToken } from "firebase/messaging";
import ApiService from "../service.api";
import {
  IonButton,
  IonContent,
  IonicSafeString,
  IonPage,
  modalController,
} from "@ionic/vue";
import { translate } from "../helper.auth";
import NotificationModal from "../../components/NotificationModal.vue";

export default {
  name: "NotificationService",
  components: { IonButton, IonContent, IonPage },
  data() {
    return {};
  },
  mounted() {
    // this.$messaging.onBackgroundMessageHandler = (message) => {
    //   console.log("background push -> -> -> ", message)
    // }

    console.log("MIXIN NOTIFICATIONS");
    this.$messaging.onMessageHandler = (message) => {
      console.log("foreground push -> -> -> ", message);
    };
    onMessage(this.$messaging, (payload) => {
      console.log("frontend push -> -> -> ", payload);
      this.openModal(payload);
      // return modal.present();
    });
    // onBackgroundMessage(this.$messaging, (payload) => {
    //   console.log("foreground push -> -> -> ", payload)
    // })

    /*

{
titolo (string),
testo (string),
img_Big (string),
img_Small (string),
id_campagna (long),
tipo_azione (ranking,badge,classroom,ticket) (string),
url (string)
}

 */
    // const payload = {"from":"992781005473","collapseKey":"do_not_collapse","notification":{"title":"Hello","body":"Yellow"}};
    // this.openModal(payload);

    //
  },
  methods: {
    async openModal(notification) {
      console.log("open modal", notification);

      const dataRemap = {
        titolo: "title",
        testo: "body",
        img_Big: "img_big",
        img_Small: "img_small",
        id_campagna: "campaign_id",
        tipo_azione: "action_type",
        url: "action_url",
        body: "body",
      };
      if (
        typeof notification.notification.body === "string" ||
        notification.notification.body instanceof String
      ) {
        // notification.notification.body = JSON.parse(notification.notification.body);
      }

      // for (let i in notification.notification.body) {
      //   // notification.notification.body[i] = (await new IonicSafeString(notification.notification.body[i])).value;
      //   if (dataRemap[i]) {
      //     notification.notification.body[dataRemap[i]] =
      //       notification.notification.body[i];
      //   }
      // }
      for (let i in notification.notification) {
        console.log(
          "dataRemap[i]",
          i,
          dataRemap[i],
          notification.notification[i]
        );
        // notification.notification.body[i] = (await new IonicSafeString(notification.notification.body[i])).value;
        if (dataRemap[i]) {
          notification.notification[dataRemap[i]] =
            notification.notification[i];
        }
      }

      const modal = await modalController.create({
        component: NotificationModal,
        cssClass: "push-notification-modal",
        componentProps: {
          data: notification.notification.body,
          // data: notification.notification,
        },
      });
      return modal.present();
    },
    async setNotificationToken() {
      console.log("SET NOTIFICATION TOKEN ", this.$messaging);
      Notification.requestPermission()
        .then((getperm) => {
          if (getperm === "granted") {
            console.log("Notification permission granted.");

            // Get Token
            getToken(this.$messaging, {
              vapidKey:
                "BFpJYgBy9M2je-2cIdb0voL5pvOQvjxaXcwCkjg8AirDIIyBUks6Vu9Gw_ikyQ8Cxs-vV8zqwUAKNA5F0zMZdxE",
            })
              .then((currentToken) => {
                if (currentToken) {
                  console.log("current token for client: ", currentToken);

                  localStorage.setItem("FCMToken", currentToken.toString());

                  ApiService.post("Notification/SetToken", {
                    token: currentToken.toString(),
                  }).then((res) => {
                    console.log(
                      "🚀 ~ file: Notification.vue:121 ~ .then ~ res:",
                      res
                    );
                    // const data = res.data;
                  });

                  // Track the token -> client mapping, by sending to backend server
                  // show on the UI that permission is secured
                } else {
                  console.log(
                    "No registration token available. Request permission to generate one."
                  );

                  // shows on the UI that permission is required
                }
              })
              .catch((err) => {
                console.log("An error occurred while retrieving token. ", err);
                // catch error while creating client token
              });
          } else {
            console.log("Permission to notify not granted");
          }
        })
        .catch((err) => {
          console.log("Unable to get permission to notify.", err);
        });
    },
  },
};
</script>
