const CLIENT_CONFIG = {
  name: "Demo",
  numTutorialImages: 16,
  showAvatarCampaigns: true,
  apiEndpoint: "https://api-demo.whappy.it/api/",
  primaryColor: "#4089bf",
  primaryColor_2: "#4050bf",
  secondaryColor: "#f5a502",
  secondaryColor_2: "#f55002",
  thirdaryColor: "#5f422e",
  thirdaryColor_2: "#838788",
  graphLightColor_1: "#4089bf",
  graphLightColor_2: "#f5a502",
  graphDarkColor_1: "#4089bf",
  graphDarkColor_2: "#f5a502",
};
export default CLIENT_CONFIG;
