<template>
  <ion-page>
    <!-- <slot name="before-header"></slot>
    <ion-header v-if="showHeader" :translucent="true">
      <ion-toolbar v-if="showToolbar">
        <ion-buttons slot="start">
          <ion-menu-button v-if="showMenu" color="primary"></ion-menu-button>

          <ion-back-button
            v-if="showBack"
            icon="/assets/icon/chevron-back-outline.svg"
            text=""
            @click="$router.go(-1)"
          ></ion-back-button>
        </ion-buttons>

        <ion-title mode="md">{{ pageTitle }}</ion-title>
      </ion-toolbar>
    </ion-header> -->

    <ion-content :fullscreen="true">
      <div
        v-if="showHeader"
        class="master-layout-header"
        :class="masterLayoutHeader"
      >
        <div
          v-if="showInfoPopHover"
          class="info-poohover"
          :class="infopopovercampaigns"
        >
          <div
            id="click-trigger"
            class="click-trigger"
            :class="clicktriggercampaigns"
          >
            i
          </div>
          <ion-popover trigger="click-trigger" trigger-action="click">
            <ion-content class="ion-padding">{{ infoPopHover }}</ion-content>
          </ion-popover>
        </div>
        <div v-if="showToolbar" class="toolbar" :class="bgClient">
          <div class="logo">
            <img
              id="photo-fullscreen"
              :src="whappyClientAsset + 'img/logo-login.png' || 'img/logo.png'"
              @error="handleImgError"
            />
          </div>
          <div class="buttons">
            <ion-menu-button
              style="color: var(--topbar-icon-color)"
              v-if="showMenu"
            ></ion-menu-button>
            <ion-icon
              v-if="showBack"
              @click="goBack"
              class="text-26"
              :icon="chevronBack"
            ></ion-icon>
          </div>
          <div v-if="showNotifications" class="notification-icon">
            <ion-icon
              :icon="notificationsOutline"
              class="text-26"
              @click="setNotificationListVisible(true)"
            ></ion-icon>
          </div>
        </div>
        <div
          v-if="
            titleStyle == 'vertical' && (smallTitle || bigTitle || pageTitle)
          "
          class="vertical-page-name"
          :class="(subtitle ? 'mb-2' : '', campaignsheader)"
        >
          <span v-if="smallTitle" class="small">{{ smallTitle }}</span>
          <span v-if="bigTitle || pageTitle" class="big">{{
            bigTitle || pageTitle
          }}</span>
          <span v-if="subtitle" class="subtitle">{{ subtitle }}</span>
        </div>
        <div
          v-if="titleStyle == 'horizontal' && (smallTitle || bigTitle)"
          class="horizontal-page-name"
          :class="subtitle ? 'mb-2' : ''"
        >
          <span v-if="smallTitle" class="small">{{ smallTitle }}</span>
          <span v-if="bigTitle" class="big">{{ bigTitle }}</span>
          <span v-if="subtitle" class="subtitle">{{ subtitle }}</span>
        </div>
        <div
          v-if="subtitle && titleStyle == 'horizontal'"
          class="axerta-hichahuevos-sub"
        ></div>
        <div
          v-if="showProfilePic && userData && titleStyle == 'vertical'"
          class="profile-in-header-vertical"
        >
          <div class="userAvatar">
            <img
              :src="userData.avatar_url"
              alt="Profile picture"
              @error="handleImgError"
            />
          </div>
          <div class="username">{{ userData.nome }} {{ userData.cognome }}</div>
        </div>
        <div
          v-if="showProfilePic && userData && titleStyle == 'horizontal'"
          class="profile-in-header-horizontal"
        >
          <div class="userAvatar">
            <img
              :src="userData.avatar_url"
              alt="Profile picture"
              @error="handleImgError"
            />
          </div>
          <div class="username">{{ userData.nome }} {{ userData.cognome }}</div>
        </div>
        <div
          v-if="showProfilePic && titleStyle == 'horizontal'"
          class="axerta-hichahuevos"
        ></div>
      </div>

      <!--      <div id="container">-->
      <div
        id="container_normal"
        :class="{
          'ion-padding': !noPadding,
          campaignscontent,
          containerForAxerta: $route.path.includes('axerta/lista-ordini'),
          containerNotForAxerta: !$route.path.includes('axerta/lista-ordini'),
          'mb-2': subtitle,
        }"
        style="background: var(--client-ground1-color)"
      >
        <!--        <strong class="capitalize">{{ $route.params.id }}</strong>-->
        <slot></slot>
      </div>
    </ion-content>
    <slot name="footer"></slot>
    <ion-modal :is-open="notificationListVisible">
      <ion-header>
        <ion-toolbar>
          <ion-title>Modal</ion-title>
          <ion-buttons slot="end">
            <ion-button @click="setNotificationListVisible(false)"
              >Close</ion-button
            >
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <div
          v-for="notification in notificationList"
          :key="notification.id"
          @click="readNotification(notification)"
        >
          <h4 :class="notification.read ? '' : 'font-bold'">
            {{ notification.title }}
          </h4>
          <div class="w-full flex justify-content-between align-items-center">
            <div class="w-20">
              <img :src="notification.image" @error="handleImgError" />
            </div>
            <div class="w-80 flex flex-column px-4">
              <p :class="notification.read ? '' : 'font-bold'">
                {{ notification.text }}
              </p>
              <p
                :class="notification.read ? '' : 'font-bold'"
                class="text-align-end text-italic"
              >
                {{
                  new Date(notification.date).toLocaleDateString("it", {
                    year: "numeric",
                    month: "short",
                    day: "2-digit",
                  })
                }}
              </p>
            </div>
          </div>
        </div>
      </ion-content>
    </ion-modal>
  </ion-page>
</template>

<script>
import axios from "axios";
import { API_MOCK_ENABLE, API_MOCK_PATH, API_URL } from "../common/config";
import { menu, chevronBack, notificationsOutline } from "ionicons/icons";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonBackButton,
  IonIcon,
  IonPopover,
  IonModal,
  IonButton,
} from "@ionic/vue";
import ApiService from "../common/service.api";
export default {
  name: "MasterLayout",
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonBackButton,
    IonIcon,
    IonPopover,
    IonModal,
    IonButton,
  },
  props: {
    titleStyle: {
      type: String,
      default: "vertical",
    },
    smallTitle: {
      type: String,
    },
    bigTitle: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    showContentHeader: {
      type: Boolean,
      default: true,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    pageTitle: {},
    showToolbar: {
      type: Boolean,
      default: true,
    },
    showMenu: {
      type: Boolean,
      default: true,
    },
    showBack: {
      type: Boolean,
      default: true,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    showProfilePic: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      // required: true,
      default: () => [],
    },
    showInfoPopHover: {
      type: Boolean,
      default: false,
    },
    infoPopHover: {
      type: String,
    },
    showNotifications: {
      type: Boolean,
      default: false,
    },
    notificationList: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    axios({
      // url: API_MOCK_PATH + 'setting.json',
      url: API_MOCK_ENABLE
        ? API_MOCK_PATH + "setting.json"
        : API_URL + "Setting",
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      this.bgClient = "bg-" + res.data.ragione_sociale.replace(/\s/g, "");
    });
    //if whappyUserData exists get whappyUserData from local storage and assign to this.userData
    if (localStorage.getItem("whappyUserData")) {
      this.userData = JSON.parse(localStorage.getItem("whappyUserData"));
    }
  },
  data() {
    return {
      menu,
      chevronBack,
      notificationsOutline,
      bgClient: "",
      notificationListVisible: false,
      userData: null,
    };
  },

  methods: {
    handleImgError() {
      console.log(
        "handleImgError",
        "https://placehold.co/300x300?text=MartechRevolution"
      );
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    goBack() {
      this.$emit("master-layout-back");
      localStorage.removeItem("axerta-currentRilevazione");
      this.$router.go(-1);
    },
    setNotificationListVisible(isOpen) {
      this.notificationListVisible = isOpen;
    },
    readNotification(notification) {
      ApiService.put(
        `Notification/SetNotificationRead/${notification.id}`
      ).then((res) => {
        console.log(res);
        this.$router.push(notification.url);
      });
    },
  },
  computed: {
    campaignsheader() {
      return this.$route.path.includes("campaigns") ? "campaignsheader" : "";
    },
    campaignscontent() {
      return this.$route.path.includes("campaigns") ? "campaignscontent" : "";
    },

    isContainerForAxerta() {
      console.log(
        "🚀 ~ file: MasterLayout.vue:333 ~ containerForAxerta ~ this.$route.path:",
        this.$route.path,
        this.$route.path.includes("axerta/lista-ordini")
      );

      if (this.$route.path.includes("axerta/lista-ordini")) {
        return "containerForAxerta";
      }

      return "containerNotForAxerta";
    },

    clicktriggercampaigns() {
      return this.$route.path.includes("campaigns")
        ? "clicktriggercampaigns"
        : "";
    },

    masterLayoutHeader() {
      if (this.$route.path.includes("axerta")) {
        if (this.showProfilePic) {
          return "header-axerta header-with-profile";
        } else {
          return "header-axerta ";
        }
      } else {
        if (this.showProfilePic) {
          return "header-not-axerta header-with-profile";
        } else {
          return "";
        }
      }
    },

    infopopovercampaigns() {
      return this.$route.path.includes("campaigns")
        ? "infopopovercampaigns"
        : "";
    },
  },
};
</script>

<style lang="scss" scoped>
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

#container a {
  text-decoration: none;
}

.toolbar {
  position: relative;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  .logo {
    width: 40%;
    height: 90%;
    display: grid;
    place-content: center;
    img {
      max-height: 50px;
      padding: 5px 5px 0 5px;
      margin: 0 auto;
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 50px;
    ion-icon {
      margin: 0 20px;
      color: var(--topbar-icon-color);
    }
    ion-icon:hover {
      cursor: pointer;
    }
  }
}

.notification-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  ion-icon {
    margin: 0 20px;
    // color: var(--topbar-icon-color);
  }
  ion-icon:hover {
    cursor: pointer;
  }
}

.horizontal-page-name {
  position: relative;
  background: var(--bg-page-name);
  height: 77px;
  display: flex;
  align-items: center;
  justify-content: center;
  .small {
    font-weight: 400;
    font-size: 18px;
    line-height: 12px;
    margin-right: 10px;
  }
  .big {
    font-weight: 700;
    font-size: 26px;
    line-height: 26px;
  }
}
.vertical-page-name {
  position: relative;
  background: var(--bg-page-name);
  height: 77px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
  .small {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
  }
  .big {
    font-weight: 700;
    font-size: 26px;
    line-height: 26px;
  }
}

.campaignsheader {
  background: var(--campaign-header-bg) !important;
  color: var(--campaign-header-text) !important;
}

.campaignscontent {
  padding-top: 235px !important;
  background-color: white !important;
}

.subtitle {
  position: absolute;
  bottom: -35px;
  font-size: 22px;
  font-weight: bold;
  // margin-bottom: 1rem;
}

.axerta-hichahuevos-sub {
  position: absolute;
  bottom: -40px;
  background-color: var(--client-ground1-color);
  // background-color: red;

  height: 50px;
  width: 100%;
  z-index: -1;
}

.bg-Axerta {
  background: url("https://axerta.whappy.it/img/axerta_bg.jpg") no-repeat center
    top / cover;
}

.bg-YoubeCosmetics {
  background: var(--client-ground1-color);
  ion-item[data-v-26084dc2]::part(native) {
    color: #8d97a8;
  }

  ion-icon {
    color: var(--ion-color-primary);
  }
}

.bg-CommercialProjectManagement {
  background: #001b50;
}

.info-poohover {
  position: absolute;
  top: 95px;
  right: 17px;
  z-index: 1001;
  color: var(--client-header-color);
  .click-trigger {
    display: grid;
    place-content: center;
    padding-bottom: 2px;
    width: 30px;
    height: 30px;
    border: 1px solid var(--client-header-color);
    border-radius: 50%;
  }
}

.infopopovercampaigns {
  color: var(--campaign-header-text) !important;
}

.clicktriggercampaigns {
  border: 1px solid var(--campaign-header-text) !important;
}

.userAvatar {
  text-align: center;
  margin: 15px 0 0;
  font-size: 12px;
  z-index: 9999;
  // height: 90px;
  // width: 90px;
  // overflow: hidden;
  img {
    border-radius: 100%;
    border: 3px solid white;
    height: 90px !important;
    width: 90px;
    object-fit: cover;
  }
}

.username {
  text-align: center;
  font-size: 1.3rem;
  padding-bottom: 0.7rem;
  width: 100vw;
}

.profile-in-header-vertical {
  position: absolute;

  right: 50%;
  transform: translateX(50%);
  top: 110px;
}

.profile-in-header-horizontal {
  position: absolute;

  right: 50%;
  transform: translateX(50%);
  top: 100px;
}

.axerta-hichahuevos {
  position: absolute;
  top: 145px;

  background-color: var(--client-ground1-color);

  height: 90px;
  width: 100%;
  content: "";

  z-index: -1;
}

.header-with-profile {
  padding-bottom: 80px;
  .vertical-page-name {
    height: 120px;
    padding-bottom: 35px;
  }
  .horizontal-page-name {
    height: 110px;
    padding-bottom: 35px;
  }
}

.header-axerta {
  //background gradient from 173563 to white
  background: linear-gradient(
    180deg,
    #173563 0%,
    #173563 50%,
    #ffffff00 51%,
    #ffffff00 100%
  ) !important;
  color: black;
}

.header-not-axerta {
  background: white !important;
}

.containerNotForAxerta {
  padding-top: 127px;
}

.containerForAxerta {
  padding-top: 220px;
  padding-bottom: 100px;
}
</style>
